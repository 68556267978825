import TicketChat from '../components/Chat/TicketChat';
import LinkChat from '../components/Chat/LinkChat';
import TagChat from '../components/Chat/TagChat';
import CategoryChat from '../components/Chat/CategoryChat';

import ForgotPasswordRequestOTP from '../containers/ForgotPassword/RequestOTP';
import ForgotPasswordValidateOTP from '../containers/ForgotPassword/ValidateOTP';
import ForgotPasswordChangePassword from '../containers/ForgotPassword/ChangePassword';

import SignUp from '../containers/SignUp';
import MainCustomerHome from '../containers/MainCustomer/Home';
import EmailValidation from '../containers/MainCustomer/EmailValidation';
import UserAdministratorInvitation from '../containers/MainCustomer/UserAdministratorInvitation';


import Product from '../components/Product/Product';
import CategoryProduct from '../components/Product/CategoryProduct';
import Gallery from '../components/Product/Gallery';

import Course from '../components/Course/Course';
import CategoryCourse from '../components/Course/CategoryCourse';

import NotificationAutoSend from '../components/Notification/NotificationAutoSend';
import NotificationManualSend from '../components/Notification/NotificationManualSend';

import Article from '../components/Content/Article';
import CategoryArticle from '../components/Content/CategoryArticle';

import SalesOrder from '../components/Sales/SalesOrder';
import PricingPlanPackageAccount from '../components/Sales/PricingPlanPackageAccount';
import Promotion from '../components/Sales/Promotion';
import Coupon from '../components/Sales/Coupon';

import Administrator from '../components/Account/Administrator';
import Customer from '../components/Account/Customer';
import Register from '../components/Account/Register';
import Role from '../components/Account/Role';
import Post from '../components/Account/Post';
import Report from '../components/Account/report'
// Technician
import Technician from '../components/Account/Technician';

import Theme from '../components/Appearance/Theme';
import Swiper from '../components/Appearance/Swiper';
import Setting from '../components/Appearance/Setting';
import Mobile from '../components/Appearance/Mobile';
import TermsAndConditions from '../components/Appearance/TermsAndConditions';
import PrivacyPolicy from '../components/Appearance/PrivacyPolicy';
import AboutUs from '../components/Appearance/AboutUs';
import ContactUs from '../components/Appearance/ContactUs';
import FrequentlyAskedQuestions from '../components/Appearance/FrequentlyAskedQuestions';


import DeliveryMethod from '../components/Setting/DeliveryMethod';
import PaymentMethod from '../components/Setting/PaymentMethod';

import Advert from '../components/Content/Advert';

import account from '../resource/Icon/Account.png'
import administrator from '../resource/Icon/Administrator.png'
import register from '../resource/Icon/Register.png'
import role from '../resource/Icon/Role.png'
import user from '../resource/Icon/User.png'
import categoryChat from '../resource/Icon/Category-Chat.png'
import chat from '../resource/Icon/Chat.png'
import tag from '../resource/Icon/Tag.png'
import ticketChat from '../resource/Icon/Ticket-Chat.png'
import article from '../resource/Icon/Article.png'
import articleCategory from '../resource/Icon/Article-Category.png'
import content from '../resource/Icon/Content.png'

import notification from '../resource/Icon/Notification.png'
import autoSend from '../resource/Icon/Auto-Send.png'
import manualSend from '../resource/Icon/Manual-Send.png'
import product from '../resource/Icon/Product.png'
import gallery from '../resource/Icon/Gallery.png'
import catalog from '../resource/Icon/Catalog.png'
import theme from '../resource/Icon/Theme.png'



export const pathList = [
    {
        id: 1,
        name: 'Chat',
        icon: chat,
        subMenuList: [
            {
                id: 1, name: 'Ticket Chat', icon: ticketChat, path: '/ticket_chat', component: TicketChat, isShowTable: true,
                url: '/api/crm/ticket/administrator',
                roleKey: 'ticket_chat', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            // {
            //     id: 2, name: 'Chat Link', path: '/link_chat', component: LinkChat, isShowTable: true,
            //     url : '/api/chat/link/list',
            //     roleKey : 'link_chat', role: { view: true, edit: true, export: true },
            // isConnectCodexLearn : false,
            // },

            {
                id: 4, name: 'Category Chat', icon: categoryChat, path: '/category_chat', component: CategoryChat, isShowTable: true,
                url: '/api/crm/administrator/category/ticket/',
                roleKey: 'category_chat', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 3, name: 'Tag', icon: tag, path: '/tag_chat', component: TagChat, isShowTable: true,
                url: '/api/administrator/crm/ticket/tag/',
                roleKey: 'tag_chat', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },

        ]
    },
    {
        id: 2,
        name: 'Product',
        icon: product,
        subMenuList: [
            {
                id: 1, name: 'Product', icon: product, path: '/product', component: Product, isShowTable: true,
                url: '/api/administrator/crm/product/',
                roleKey: 'product', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 2, name: 'Category Product', icon: catalog, path: '/category_product', component: CategoryProduct, isShowTable: true,
                url: '/api/administrator/crm/category/product/',
                roleKey: 'category_product', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 3, name: 'Gallery', icon: gallery, path: '/gallery', component: Gallery, isShowTable: true,
                url: '/api/administrator/gallery',
                roleKey: 'gallery', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 4, name: 'Category Work', icon: register, path: '/category_work', component: Register, isShowTable: true,
                url: '/api/administrator/crm/category/work',
                roleKey: 'category_work', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
                isTechnicianApp: true,
            },
        ]
    },
    {
        id: 22,
        name: 'Course',
        icon: product,
        subMenuList: [
            {
                id: 1, name: 'Course', icon: product, path: '/course', component: Course, isShowTable: true,
                url: '/learn/connect/administrator/course',
                roleKey: 'course', role: { view: true, edit: false, export: false },
                isConnectCodexLearn: true,
            },
            {
                id: 2, name: 'Category Course', icon: catalog, path: '/category_course', component: CategoryCourse, isShowTable: true,
                url: '/learn/connect/administrator/category',
                roleKey: 'category_product', role: { view: true, edit: false, export: false },
                isConnectCodexLearn: true,
            },
        ]
    },
    {
        id: 3,
        name: 'Broad cast',
        icon: notification,
        subMenuList: [
            // {
            //     id: 1, name: 'Auto Send', icon: autoSend, path: '/notification_auto_send', component: NotificationAutoSend, isShowTable: true,
            //     url: '/api/administrator/crm/notification/auto/send/',
            //     roleKey: 'notification_auto_send', role: { view: true, edit: true, export: true },
            // isConnectCodexLearn : false,
            // },
            {
                id: 2, name: 'Board Cast', icon: manualSend, path: '/notification_manual_send', component: NotificationManualSend, isShowTable: true,
                url: '/api/administrator/crm/notification/manual',
                roleKey: 'notification_manual_send', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            }
        ]
    },
    {
        id: 4,
        name: 'Content',
        icon: content,
        subMenuList: [
            {
                id: 1, name: 'Article', icon: article, path: '/article', component: Article, isShowTable: true,
                url: '/api/crm/article/administrator',
                roleKey: 'article', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 2, name: 'Category Article', icon: articleCategory, path: '/category_article', component: CategoryArticle, isShowTable: true,
                url: '/api/crm/administrator/category/article',
                roleKey: 'category_article', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 3, name: 'Advert', icon: articleCategory, path: '/advert', component: Advert, isShowTable: true,
                url: '/api/crm/administrator/advert',
                roleKey: 'advert', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
                isTechnicianApp: true,
            }
        ]
    },
    {
        id: 7,
        name: 'Sales',
        icon: account,
        subMenuList: [
            {
                id: 1, name: 'Sales Order', icon: role, path: '/sales_order', component: SalesOrder, isShowTable: true,
                url: '/api/crm/administrator/sales/order',
                roleKey: 'sales_order', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 5, name: 'Pricing plan', icon: role, path: '/package_account', component: PricingPlanPackageAccount, isShowTable: true,
                url: '/api/crm/administrator/package',
                roleKey: 'package_account', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
                isTechnicianApp: true,
            },
            {
                id: 2, name: 'Coupon', icon: role, path: '/coupon', component: Coupon, isShowTable: true,
                url: '/api/crm/administrator/sales/coupon',
                roleKey: 'coupon', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 3, name: 'Promotion', icon: role, path: '/promotion', component: Promotion, isShowTable: true,
                url: '/api/crm/administrator/sales/promotion',
                roleKey: 'promotion', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
        ]
    },

    {
        id: 5,
        name: 'Account',
        icon: account,
        subMenuList: [
            {
                id: 1, name: 'Role Staff', icon: role, path: '/role_administrator', component: Role, isShowTable: true,
                url: '/api/crm/administrator/account/role',
                roleKey: 'role_administrator', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 2, name: 'User Staff', icon: administrator, path: '/user_administrator', component: Administrator, isShowTable: true,
                url: '/api/crm/administrator/user/administrator',
                roleKey: 'user_administrator', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 3, name: 'User Customer', icon: user, path: '/user_customer', component: Customer, isShowTable: true,
                url: '/api/crm/administrator/user/customer',
                roleKey: 'user_customer', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 3, name: 'User Technician', icon: user, path: '/user_technician', component: Technician, isShowTable: true,
                url: '/api/crm/administrator/user/technician',
                roleKey: 'user_customer', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
                isTechnicianApp: true,
            },
            {
                id: 4, name: 'Custom Register', icon: register, path: '/register_customer', component: Register, isShowTable: false,
                url: '/api/account/register/list',
                roleKey: 'register_customer', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 5, name: 'Post', icon: register, path: '/job_post', component: Post, isShowTable: true,
                url: '/api/crm/administrator/user/postwork',
                roleKey: 'job_post', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
                isTechnicianApp: true,
            },
            {
                id: 6, name: 'Report', icon: register, path: '/user_report', component: Report, isShowTable: true,
                url: '/api/crm/customer/user/reportuser',
                roleKey: 'user_report', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
                isTechnicianApp: true,
            },
        ]
    },
    {
        id: 6,
        name: 'Appearance',
        icon: theme,
        subMenuList: [
            {
                id: 1, name: 'Theme', path: '/theme', icon: theme, component: Theme, isShowTable: false,
                url: '/api/appearance/theme/list',
                roleKey: 'theme', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 2, name: 'Swiper', path: '/swiper', icon: theme, component: Swiper, isShowTable: false,
                url: '/api/appearance/swiper/list',
                roleKey: 'swiper', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 3, name: 'Setting', path: '/setting', icon: theme, component: Setting, isShowTable: false,
                url: '/api/appearance/setting/list',
                roleKey: 'setting', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 4, name: 'Mobile', path: '/mobile', icon: theme, component: Mobile, isShowTable: false,
                url: null,
                roleKey: 'mobile', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 5, name: 'Terms And Conditions', path: '/terms_and_conditions', icon: theme, component: TermsAndConditions, isShowTable: false,
                url: null,
                roleKey: 'terms_and_conditions', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 6, name: 'Privacy Policy', path: '/privacy_policy', icon: theme, component: PrivacyPolicy, isShowTable: false,
                url: null,
                roleKey: 'privacy_policy', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 8, name: 'About Us', path: '/about-us', icon: theme, component: AboutUs, isShowTable: false,
                url: null,
                roleKey: 'about_us', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 9, name: 'Contact Us', path: '/contact-us', icon: theme, component: ContactUs, isShowTable: false,
                url: null,
                roleKey: 'contact_us', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 10, name: 'FAQ', path: '/frequently-asked-questions', icon: theme, component: FrequentlyAskedQuestions, isShowTable: false,
                url: null,
                roleKey: 'frequently_asked_questions', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            }
            
        ]
    },
    {
        id: 8,
        name: 'Setting',
        icon: account,
        subMenuList: [
            {
                id: 1, name: 'Delivery Method', icon: role, path: '/delivery_method', component: DeliveryMethod, isShowTable: true,
                url: '/api/crm/administrator/delivery/method',
                roleKey: 'delivery_method', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
            {
                id: 1, name: 'Payment Method', icon: role, path: '/payment_method', component: PaymentMethod, isShowTable: true,
                url: '/api/crm/administrator/payment/method',
                roleKey: 'payment_method', role: { view: true, edit: true, export: true },
                isConnectCodexLearn: false,
            },
        ]
    },

]


export const menuList = pathList.map(item => {
    let tempItem = { id: item.id, name: item.name, icon: item.icon }
    let tempsubMenuList = item.subMenuList.map(subItem => {
        return { id: subItem.id, name: subItem.name, path: subItem.path, icon: subItem.icon, roleKey: subItem.roleKey, isConnectCodexLearn: subItem.isConnectCodexLearn,isTechnicianApp: subItem.isTechnicianApp }
    })
    tempItem['subMenuList'] = tempsubMenuList
    return tempItem
})

export const adminPath = []
pathList.map(item => {
    item.subMenuList.map(subItem => {
        adminPath.push({
            path: subItem.path,
            component: subItem.component,
            isShowTable: subItem.isShowTable,
            name: item.name,
            subName: subItem.name,
            url: subItem.url,
            icon: subItem.icon,
            roleKey: subItem.roleKey,
            isConnectCodexLearn: subItem.isConnectCodexLearn,
        })
    })
})


export const publicAdministratorPath = [
    {
        key: 1,
        path: '/forgot_password/requestotp',
        component: ForgotPasswordRequestOTP
    },
    {
        key: 2,
        path: '/forgot_password/validateotp',
        component: ForgotPasswordValidateOTP
    },
    {
        key: 3,
        path: '/forgot_password/changepassword',
        component: ForgotPasswordChangePassword
    },
    {
        key: 4,
        path: '/signup',
        component: SignUp
    },
    {
        key: 4,
        path: '/main/home',
        component: MainCustomerHome
    },
    {
        key: 5,
        path: '/validation/email',
        component: EmailValidation
    },
    {
        key: 5,
        path: '/invitation/administrator',
        component: UserAdministratorInvitation
    },

]


// export const roleList = pathList.map(item => {
//     let tempItem = { id: item.id, name: item.name }
//     let tempsubMenuList = item.subMenuList.map(subItem => {
//         return {
//             id: subItem.id, name: subItem.name,
//             view: subItem.role.view, edit: subItem.role.edit, export: subItem.role.export
//         }
//     })
//     tempItem['subMenuList'] = tempsubMenuList
//     return tempItem
// })

export const roleList = []
pathList.map(item => {
    item.subMenuList.map(subItem => {
        roleList.push({
            key: subItem.roleKey,
            name: subItem.name,
            view: subItem.role.view,
            edit: subItem.role.edit,
            export: subItem.role.export,
            icon: subItem.icon,
            isConnectCodexLearn: subItem.isConnectCodexLearn,
        })
    })
})
