import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Icon, notification } from 'antd';
import { get, post, ppost, put } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import LoadingModal from '../../../containers/CustomModal/LoadingModal';
// import UploadImageModal from '../../../containers/CustomModal/UploadImageModal';
import NotificationManualModal from '../../Notification/NotificationManualSend/NotificationManualModal'
import './style.scss'

notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            uploading: false,
            visible: false,
            name: '',
            categoryProductId: null,
            categoryTicketId: null,
            detail: '',
            weight: 0,
            total: 0,
            purchase: 0,
            inventory: 0,
            price: 0,
            priceForSale: 0,
            detailList: [],
            descriptionList: [],
            imageCoverList: [],
            imageDetailList: [],

            languageList: [],
            categoryProductList: [],
            categoryTicketList: [],

            productId: null
        }
    }

    setStatusLoading(status) {
        this.setState({ loading: status })
    }

    getLanguageList() {
        let url = '/api/crm/administrator/language/raw';
        return get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ languageList: responses })
                let detailList = []
    
                responses.map(item=>{
                    detailList.push({ name: '', language_id: item.id })
                })

                this.setState({ languageList: responses,detailList:detailList })
                return responses
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getCategoryProductList() {
        let url = '/api/category/product/';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ categoryProductList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getCategoryTicketList() {
        let url = '/api/crm/administrator/category/ticket/';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ categoryTicketList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getProductDetail(id) {
        const { folderName } = this.props
        const { languageList } = this.state
        let url = '/api/administrator/product/' + id;
        return get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                let imageCoverList = []
                if (response.image) {
                    imageCoverList = [{
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                        file: null,
                        name: response.image
                    }]
                }
                let imageDetailList = response.imageDetailList.map(item => {
                    return {
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`,
                        file: null,
                        name: item.name
                    }
                })
                languageList.map(item=>{

                    let index = response.detailList.findIndex(itemData=> itemData.language_id == item.id)
                    console.log('indexindexindex',languageList)
                    if(index == -1){
                        response.detailList.push({ name: '', language_id: item.id })
                    }
                })
                
                this.setState({
                    productId: response.id,
                    name: response.name,
                    categoryProductId: response.category_product_id,
                    categoryTicketId: response.category_ticket_id,
                    detail: response.detail,
                    weight: response.weight,
                    total: response.total,
                    purchase: response.purchase,
                    inventory: response.inventory,
                    price: response.price,
                    priceForSale: response.price_for_sale,
                    detailList: response.detailList,
                    descriptionList: response.descriptionList,
                    imageCoverList: imageCoverList,
                    imageDetailList: imageDetailList,
                })
                return response
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async componentDidMount() {
        const { editId } = this.props
        await this.getLanguageList()
        this.getCategoryProductList()
        this.getCategoryTicketList()
        if (editId) {
            await this.getProductDetail(editId)
        }

    }

    updateProductData(data, type) {
        if (this.props.editId) {
            let url = '/api/administrator/crm/product/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.setStatusLoading(false)
                    if (type === 'send') {
                        this.setState({ visible: true })
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    this.setStatusLoading(false)
                    console.log(err.message)
                })
        } else {
            let url = '/api/administrator/crm/product';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.setStatusLoading(false)
                    if (type === 'send') {
                        this.setState({ visible: true })
                        this.getProductDetail(response.productId)
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    this.setStatusLoading(false)
                    console.log(err.message)
                })
        }

    }

    async uploadImageToServer(formData) {
        console.log('formData', formData)
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateProduct(type) {
        const { name, categoryProductId, categoryTicketId, detail, detailList, descriptionList, weight, total, purchase, priceForSale, inventory, price, imageCoverList, imageDetailList } = this.state
        if (!name || name === '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Product Name".')
            return
        }
        if (!categoryProductId || categoryProductId === '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Category Product".')
            return
        }
        if (!categoryTicketId || categoryTicketId === '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Category Chat".')
            return
        }

        this.setStatusLoading(true)

        let data = {
            name: name,
            category_product_id: categoryProductId,
            category_ticket_id: categoryTicketId,
            weight: weight,
            detail: detail,
            total: total,
            purchase: purchase,
            inventory: inventory,
            price: price,
            price_for_sale: priceForSale,
            image: null,
            detailList: JSON.stringify(detailList),
            descriptionList: JSON.stringify(descriptionList),
            imageDetailList: '[]',
            user: this.props.administratorId
        }
        //upload รูป cover
        let uploadimageCoverList = imageCoverList.filter(item => item.file)
        let uploadDataimageCoverList = imageCoverList.filter(item => !item.file)
        if (uploadimageCoverList.length) {
            let formData = new FormData();
            uploadimageCoverList.map((item, index) => formData.append("product" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                data.image = fileNameList[0]
            }
        } else {
            if (uploadDataimageCoverList.length) {
                data.image = imageCoverList[0].name
            }
        }

        //upload รูป detail
        let uploadimageDetailList = imageDetailList.filter(item => item.file)
        let uploadDataHomePageList = imageDetailList.filter(item => !item.file).map(item => item.name)
        if (uploadimageDetailList.length) {
            let formData = new FormData();
            uploadimageDetailList.map((item, index) => formData.append("product" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            fileNameList.map(fileName => {
                uploadDataHomePageList.push(fileName)
            })
        }
        data.imageDetailList = JSON.stringify(uploadDataHomePageList)
        this.updateProductData(data, type)
    }

    onClickOk() {
        this.updateProduct('save')
    }
    onClickCancel() {
        this.setState({
            name: '',
            status: 'ยังไม่ส่ง',
            description: ''
        })
        this.props.backpage()
    }
    onClickSaveAndSend() {
        this.updateProduct('send')
    }

    onChangeNameDetailList(languageId, index, value) {
        let targetDetailList = this.state.detailList.filter(item => item.language_id === languageId)
        let otherDetailList = this.state.detailList.filter(item => item.language_id !== languageId)
        targetDetailList[index].name = value
        let detailList = [...otherDetailList, ...targetDetailList].sort((a, b) => a.language_id - b.language_id)
        this.setState({ detailList: detailList })
    }

    onDeleteDetailList(index) {
        let detailList = this.state.detailList.filter((item, i) => i != index)
        this.setState({ detailList: detailList })
    }

    onAddLanguageList() {
        let detailList = this.state.detailList.map(item => Object.assign({}, item))
        detailList.push({ name: '', language_id: null })
        this.setState({ detailList: detailList })
    }
    updateLanguageList(prevLanguageId, languageId) {
        let detailList = this.state.detailList.map(item => {
            let temp = Object.assign({}, item)
            if (temp.language_id === prevLanguageId) {
                temp.language_id = languageId
            }
            return temp
        })
        this.setState({ detailList: detailList.sort((a, b) => a.language_id - b.language_id) })
    }

    updateDescription(languageId, description) {
        let descriptionList = this.state.descriptionList.map(item => Object.assign({}, item))
        let index = this.state.descriptionList.findIndex(item => item.language_id === languageId)
        if (index !== -1) {
            descriptionList[index].description = description
        } else {
            descriptionList.push({
                language_id: languageId,
                description: description
            })
        }
        this.setState({ descriptionList: descriptionList })
    }

    onAddDetailList(languageId) {
        let detailList = this.state.detailList.map(item => Object.assign({}, item))
        detailList.push({ name: '', language_id: languageId })
        this.setState({ detailList: detailList })
    }

    onCloseNotificationManual() {
        this.setState({ visible: false })
        this.props.backpage()
    }

    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutInline = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };

        const { visible } = this.state
        const detailLanguageList = []
        this.state.detailList.map(row => {
            let index = detailLanguageList.findIndex(item => item.language_id === row.language_id)
            if (index !== -1) {
                detailLanguageList[index].detail_list.push(row)
            } else {
                detailLanguageList.push({
                    language_id: row.language_id,
                    detail_list: [row]
                })
            }
        })
        detailLanguageList.map(row => {
            let descriptionList = this.state.descriptionList.filter(item => item.language_id === row.language_id)
            row['description'] = descriptionList.length ? descriptionList[0].description : ''
        })
        return (
            <div style={{ backgroundColor: '#FFFFFF', height: '100vh' }}>
                <div className="header-card" >
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'left', marginBottom: 10, cursor: 'pointer' }} onClick={() => this.props.backpage()}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit  Product' : 'Create Product'}</span>
                    </Col>
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'right', marginBottom: 10 }}>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(55, 168, 0)', color: '#ffffff', border: '1px solid rgb(55, 168, 0)' }}
                            onClick={() => this.onClickSaveAndSend()}
                        >
                            {'Save & Send'}
                        </Button>
                    </Col>
                </div>
                <Card className="cardstyle">
                    {/* <Meta title="Product" /> */}
                    <Form>
                        <Row gutter={24}>
                            <Col sm={24} md={12} lg={6}  >
                                <FormItem {...formItemLayout} label={'Name'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Category Product'} required={true}>
                                    <Select
                                        value={this.state.categoryProductId}
                                        onChange={(value) => this.setState({ categoryProductId: value })}
                                    >
                                        {this.state.categoryProductList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Category Chat'} required={true}>
                                    <Select
                                        value={this.state.categoryTicketId}
                                        onChange={(value) => this.setState({ categoryTicketId: value })}
                                    >
                                        {this.state.categoryTicketList.map((item, index) => <Option key={index} value={item.id}>{item.Name}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={3} xl={3} >
                                <FormItem {...formItemLayout} label={'Weight'}>
                                    <Input
                                        value={this.state.weight}
                                        type={'number'}
                                        onChange={(event) => this.setState({ weight: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={3} xl={3} >
                                <FormItem {...formItemLayout} label={'Total'}>
                                    <Input
                                        value={this.state.total}
                                        type={'number'}
                                        onChange={(event) => this.setState({ total: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={2} xl={2} >
                                <FormItem {...formItemLayout} label={'Purchase'}>
                                    <Input
                                        value={this.state.purchase}
                                        type={'number'}
                                        onChange={(event) => this.setState({ purchase: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col sm={24} md={12} lg={6}  >
                                <FormItem {...formItemLayout} label={'Inventory'}>
                                    <Input
                                        value={this.state.inventory}
                                        type={'number'}
                                        onChange={(event) => this.setState({ inventory: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}  >
                                <FormItem {...formItemLayout} label={'Price'}>
                                    <Input
                                        value={this.state.price}
                                        type={'number'}
                                        onChange={(event) => this.setState({ price: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}  >
                                <FormItem {...formItemLayout} label={'Price for sale'}>
                                    <Input
                                        value={this.state.priceForSale}
                                        type={'number'}
                                        onChange={(event) => this.setState({ priceForSale: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        {/* <Row gutter={24}>
                            <Col sm={24} md={24} lg={24} style={{ textAlign: "end", marginBottom: 10 }} >
                                <Button onClick={() => this.onAddLanguageList()} style={{ width: '25%', backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }}>{'+ Add'}</Button>
                            </Col>
                        </Row> */}
                        {detailLanguageList.map((row, i) => {
                            return (
                                <Row style={{ padding: 10, border: "1px solid #d9d9d9", borderRadius: 5, marginBottom: 10 }}>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                        <FormItem {...formItemLayoutInline} label={'Line ID'}>
                                            <p style={{ margin: 0, fontSize: 14, padding: 5 }}>{`0000${i + 1}`}</p>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                        <FormItem {...formItemLayoutInline} label={'Language'} required={true}>
                                            <Select
                                                allowClear={true}
                                                value={row.language_id}
                                                onChange={(value) => this.updateLanguageList(row.language_id, value)}
                                            >
                                                {this.state.languageList.length ?
                                                    this.state.languageList.map((item, index) => <Option key={index} value={item.id} disabled={detailLanguageList.map(r => r.language_id).includes(item.id)} >{item.name}</Option>)
                                                    : null}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ textAlign: "end" }}>
                                        <Button onClick={() => this.onAddDetailList(row.language_id)} style={{ width: '50%', backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }}>{'Add Detail'}</Button>
                                    </Col>
                                    {/* <Row gutter={24}>
                                        
                                        <FormItem {...formItemLayout} label={'Detail'} style={{ marginTop: '30px' }}>
                                            <TextArea
                                                autosize={{ minRows: 6 }}
                                                value={this.state.detail}
                                                onChange={(event) => this.setState({ detail: event.target.value })}

                                            // onChange={(event) => this.updateDescription(row.language_id, event.target.value)}
                                            />
                                        </FormItem>
                                    </Row> */}
                                    <Col sm={24} md={24} lg={24} >
                                        <FormItem {...formItemLayout} label={'Detail'} style={{ marginTop: '30px' }}>
                                            <TextArea
                                                autosize={{ minRows: 6 }}
                                                value={row.description}
                                                onChange={(event) => this.updateDescription(row.language_id, event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    {row.detail_list.map((item, index) => {
                                        return (
                                            <Col sm={24} md={24} lg={24} style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }} >
                                                <Input
                                                    value={item.name}
                                                    onChange={(event) => this.onChangeNameDetailList(row.language_id, index, event.target.value)}
                                                />
                                                <Button onClick={() => this.onDeleteDetailList(index)} style={{ marginLeft: '5px', backgroundColor: '#ff888e', color: '#ffffff', border: '1px solid #ff888e' }}>{'Delete'}</Button>
                                            </Col>
                                        )
                                    })}

                                    {/* <Col sm={24} md={24} lg={24} >
                                        <FormItem {...formItemLayout} label={'Detail'} style={{ marginTop: '30px' }}>
                                            <TextArea
                                                autosize={{ minRows: 6 }}
                                                value={row.description}
                                                onChange={(event) => this.updateDescription(row.language_id, event.target.value)}
                                            />
                                        </FormItem>
                                    </Col> */}
                                </Row>
                            )
                        })}

                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={24} >


                                <Row>
                                    <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                        <FormItem {...formItemLayout} label={'Cover Image'} style={{ marginTop: '30px' }}>
                                            <ImageUpload
                                                label={'Upload cover image'}
                                                limit={1}
                                                dataSource={this.state.imageCoverList}
                                                updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={24} md={24} lg={24}>
                                <Row>
                                    <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                        <FormItem {...formItemLayout} label={'Detail Image'} style={{ marginTop: '30px', marginBottom: '100px' }}>
                                            <ImageUpload
                                                label={'Upload detail image'}
                                                limit={99}
                                                dataSource={this.state.imageDetailList}
                                                updateDataSource={(dataSource) => this.setState({ imageDetailList: dataSource })}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <NotificationManualModal
                    visible={visible}
                    notificationType={'product'}
                    productId={this.state.productId}
                    onClose={() => this.onCloseNotificationManual()}
                />
                <LoadingModal
                    visible={this.state.loading}
                    onClose={() => this.setState({ loading: false })}
                />
                {/* <UploadImageModal
                    visible={this.state.uploading}
                    onClose={() => this.setState({ uploading: false })}
                /> */}
            </div >
        );
    }
}

Product.propTypes = {
    editId: PropTypes.number,
}

Product.defaultProps = {
    editId: null,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(Product);

