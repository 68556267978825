
import { get,del,ppost } from './index';

export function getStorageDetail(){
    let url = '/api/crm/administrator/setting/storage';
    return get(url)
        .then(responses => {
            return {success: true, storage: responses}
        })
        .catch(error => {
            console.log(error.message)
            return {success: false, error: error}
        })
}

export function deleteFile(fileName,url="/api/crm/customer/upload/file") {
    return del(url, { fileName })
        .then((response) => {
            return {success:true,data: response}
        })
        .catch(err => {
            return {success:false,error: err}
        })
}


export async function uploadImageToServerCompressed(formData){
    // compressed image to width 1080
    let url = '/api/crm/administrator/upload/image'
    return await ppost(url, formData)
        .then(response => {
            return response
        })
        .catch(err => {
            console.log(err.message)
            return []
        })
}

export async function uploadImageToServer(formData) {
    let url = '/api/crm/administrator/upload/file'
    return await ppost(url, formData)
        .then(response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response
        })
        .catch(err => {
            console.log(err.message)
            return []
        })
}