import React,{lazy,Suspense} from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Icon, notification, Button,Modal,Spin } from 'antd';
import { get, post } from '../../../utils';
import { deleteFile,uploadImageToServerCompressed ,uploadImageToServer} from '../../../utils/helper';
import './style.scss'
import { Link } from 'react-router-dom'
import swiperHomePage from '../../../resource/swiper/swiper-home-page.png'
import swiperGetStartedPage from '../../../resource/swiper/swiper-get-started-page.png'

const ImageUpload = lazy(() => import('../../../containers/ImageUpload'));
const ImageUploadModal = lazy(() => import('../../../containers/ImageUpload/ImageUploadModal'));
const VideoUpload = lazy(() => import('../../../containers/VideoUpload'));

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Swiper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            swiperHomePageList: [],
            swiperGetStartedPageList: [],

            deleteFileList:[],

            swiperBannerList: [],
            headerBackground: [],
            swiperThumbnailVideoList: [],
            swiperVideoList: [],
        }
    }


    async setEditData(responses) {
        // console.log('responsesresponsesresponses',responses)
        
        
        let swiperHomePageList = await this.filterList(responses,'HOME_PAGE')

        let swiperGetStartedPageList = await this.filterList(responses,'GET_STARTED_PAGE')

        let swiperBannerList = await this.filterList(responses,'BANNER_HOME_PAGE')

        let swiperVideoList = await this.filterList(responses,'VIDEO_HOME_PAGE')

        let swiperThumbnailVideoList = await this.filterList(responses,'THUMBNAIL_VIDEO_HOME_PAGE')

        let headerBackground = await this.filterList(responses,'HEADER_BACKGROUND_IMAGE')

        this.setState({
            swiperHomePageList: swiperHomePageList,
            swiperGetStartedPageList: swiperGetStartedPageList,
            swiperBannerList: swiperBannerList,
            swiperVideoList: swiperVideoList,
            swiperThumbnailVideoList: swiperThumbnailVideoList,
            headerBackground: headerBackground,
        })
    }

    filterList(responses,itemCode){
        const { folderName } = this.props
        let filterList = responses.filter(item => item.code == itemCode).map(item => {
            let temp = Object.assign({}, item)
            temp['view'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`
            temp['file'] = null
            return temp
        })
        return filterList
    }

    getSwiperList() {
        let url = '/api/crm/appearance/swiper/administrator';
        get(url)
            .then(responses => {
                this.setEditData(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getSwiperList()
    }

    async updateSwiper() {
        const { swiperHomePageList, swiperGetStartedPageList,swiperVideoList,swiperBannerList,swiperThumbnailVideoList,headerBackground } = this.state
        this.setState({loading:true})
        let uploadDataHomePageList = await this.setUpdateDataAndUpload(swiperHomePageList,'HOME_PAGE',"image")

        let uploadDataGetStartedPageList = await this.setUpdateDataAndUpload(swiperGetStartedPageList,'GET_STARTED_PAGE',"image")

        let uploadDataThumbnailVideoHomePageList = await this.setUpdateDataAndUpload(swiperThumbnailVideoList,'THUMBNAIL_VIDEO_HOME_PAGE',"image")

        let uploadDataVideoHomePageList = await this.setUpdateDataAndUpload(swiperVideoList,'VIDEO_HOME_PAGE',"video")

        let uploadDataBannerHomePageList = await this.setUpdateDataAndUpload(swiperBannerList,'BANNER_HOME_PAGE',"image")

        let uploadDataHeaderBackgroundHomeList = await this.setUpdateDataAndUpload(headerBackground,'HEADER_BACKGROUND_IMAGE',"image")

        this.saveDeleteFileList()
        
        let data = {
            swiperHomePageList: JSON.stringify(uploadDataHomePageList),
            swiperGetStartedPageList: JSON.stringify(uploadDataGetStartedPageList),
            swiperVideoList: JSON.stringify(uploadDataVideoHomePageList),
            swiperBannerList: JSON.stringify(uploadDataBannerHomePageList),
            swiperThumbnailVideoList: JSON.stringify(uploadDataThumbnailVideoHomePageList),
            swiperHeaderBackgroundHomeList: JSON.stringify(uploadDataHeaderBackgroundHomeList),
        }
        

        let url = '/api/crm/administrator/appearance/swiper';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getSwiperList()
                this.setState({loading:false})
                openNotificationWithIcon('success', 'System notification', 'Save swiper success.')
                return
            })
            .catch(err => {
                console.log(err.message)
                this.setState({loading:false})
            })
    }
    
    saveDeleteFileList() {
        let deleteFileList = this.state.deleteFileList
        let url = '/api/crm/customer/upload/file'
        return  Promise.all(deleteFileList.map(deleteFileName => {
            return deleteFile(deleteFileName,url)
        }))
    }
    
    async setUpdateDataAndUpload(datasource,itemCode,type){
        // let uploadDatasourceList = datasource.filter(item => item.file)
        let uploadDataDatasourceList = []

        if (datasource.length) {
            // ใช้วิธีนี้เพราะในเคสแก้ไขรูป อยากให้รูปอยู่ตำแหน่่งเดืม
            let fileNameList = await Promise.all(datasource.map( async (item, index) => {
                if(!item.file){
                    return item
                }
                let formData = new FormData();
                formData.append(type + index, item.file)
                let fileNameListUpload = null

                if(type == 'image'){
                    fileNameListUpload = await uploadImageToServerCompressed(formData)
                }else{
                    fileNameListUpload = await uploadImageToServer(formData)
                }
                
                return { name: fileNameListUpload[0], link: item.link ? item.link : '' }
            }))

            fileNameList.map(item => {
                uploadDataDatasourceList.push({
                    code: itemCode,
                    name: item.name,
                    link: item.link,
                })
            })
        }

        return uploadDataDatasourceList
    }

    onClickOk() {
        this.updateSwiper()
    }

    onClickCancel() {
        this.getSwiperList()
    }

    getRoleEnabled() {
        const { loading } = this.state
        const { roleDetail } = this.props
        if(loading){
            return true
        }
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'swiper')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    renderModalUpload() {
        return(
            <Modal 
                title={null}
                footer={null}
                visible={this.state.loading} 
                closable={false}
            >
                <div style={{ textAlign:'center' }}>
                    <Spin />
                </div>
                <div style={{ textAlign:'center' }}>
                    กรุณารออัพโหลด...
                </div>
            </Modal>
        )
    }

    render() {
        const { 
            swiperHomePageList, swiperGetStartedPageList,swiperVideoList,swiperBannerList,swiperThumbnailVideoList,headerBackground,
            deleteFileList
        } = this.state

        return (
            <div className='swiper-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-swiper" >
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} >
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >Swiper</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Suspense fallback={<div>laoding ...</div>} >
                    <Form>
                        <Row gutter={24} style={{ backgroundColor: '#FFFFFF' }}>
                            <ImageUpload
                                className={'image-swiper-get-started-page'}
                                label={'Upload image swiper get started page'}
                                previewImage={swiperGetStartedPage}
                                limit={10}
                                dataSource={swiperGetStartedPageList}
                                updateDataSource={(dataSource) => this.setState({ swiperGetStartedPageList: dataSource })}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUploadModal
                                className={'image-swiper-home-page'}
                                label={'Upload image swiper home page'}
                                previewImage={swiperHomePage}
                                limit={10}
                                dataSource={swiperHomePageList}
                                updateDataSource={(dataSource) => this.setState({ swiperHomePageList: dataSource })}

                                isSaveDelete={true}
                                deleteFileList={deleteFileList}
                                updateDataDelete={(dataSource)=> null}
                                updateDeleteFileList={(dataSource)=>this.setState({deleteFileList: dataSource})}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUploadModal
                                className={'image-swiper-home-page'}
                                label={'Upload image banner'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={swiperBannerList}
                                updateDataSource={(dataSource) => this.setState({ swiperBannerList: dataSource })}

                                isSaveDelete={true}
                                deleteFileList={deleteFileList}
                                updateDataDelete={(dataSource)=> null}
                                updateDeleteFileList={(dataSource)=>this.setState({deleteFileList: dataSource})}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUpload
                                className={'image-swiper-home-page'}
                                label={'Upload background header'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={headerBackground}
                                updateDataSource={(dataSource) => this.setState({ headerBackground: dataSource })}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUpload
                                className={'image-swiper-home-page'}
                                label={'Upload thumbnail video'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={swiperThumbnailVideoList}
                                updateDataSource={(dataSource) => this.setState({ swiperThumbnailVideoList: dataSource })}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <VideoUpload
                                className={'video-swiper-home-page'}
                                label={'Upload video'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={swiperVideoList}
                                updateDataSource={(dataSource) => this.setState({ swiperVideoList: dataSource })}
                            />
                        </Row>
                    </Form>
                    </Suspense>
                </Card>
                {this.renderModalUpload()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    folderName: state.login.folderName,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(Swiper);

